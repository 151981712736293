export default {
  GETTING_CONNECTIONS_PENDING: 'GETTING_CONNECTIONS_PENDING',
  GETTING_CONNECTIONS_SUCCESS: 'GETTING_CONNECTIONS_SUCCESS',
  GETTING_CONNECTIONS_ERROR: 'GETTING_CONNECTIONS_ERROR',

  CREATING_CONNECTIONS_SUCCESS: 'CREATING_CONNECTIONS_SUCCESS',

  UPDATING_CONNECTIONS_SUCCESS: 'UPDATING_CONNECTIONS_SUCCESS',

  DELETING_CONNECTIONS_PENDING: 'DELETING_CONNECTIONS_PENDING',
  DELETING_CONNECTIONS_SUCCESS: 'DELETING_CONNECTIONS_SUCCESS',
  DELETING_CONNECTIONS_ERROR: 'DELETING_CONNECTIONS_ERROR',

  CONNECTING_CONNECTIONS_PENDING: 'CONNECTING_CONNECTIONS_PENDING',
  CONNECTING_CONNECTIONS_SUCCESS: 'CONNECTING_CONNECTIONS_SUCCESS',
  CONNECTING_CONNECTIONS_ERROR: 'CONNECTING_CONNECTIONS_ERROR',

  DISCONNECTING_CONNECTIONS_PENDING: 'DISCONNECTING_CONNECTIONS_PENDING',
  DISCONNECTING_CONNECTIONS_SUCCESS: 'DISCONNECTING_CONNECTIONS_SUCCESS',
  DISCONNECTING_CONNECTIONS_ERROR: 'DISCONNECTING_CONNECTIONS_ERROR',

  GETTING_MULTIMAPS_PENDING: 'SET_MULTIMAPS_PENDING',
  GETTING_MULTIMAPS_SUCCESS: 'SET_MULTIMAPS_SUCCESS',
  GETTING_MULTIMAPS_ERROR: 'SET_MULTIMAPS_ERROR',

  DELETING_MULTIMAPS_PENDING: 'DELETING_MULTIMAPS_PENDING',
  DELETING_MULTIMAPS_SUCCESS: 'DELETING_MULTIMAPS_SUCCESS',
  DELETING_MULTIMAPS_ERROR: 'DELETING_MULTIMAPS_ERROR',

  CREATING_MULTIMAPS_SUCCESS: 'CREATING_MULTIMAPS_SUCCESS',

  UPDATING_MULTIMAPS_SUCCESS: 'UPDATING_MULTIMAPS_SUCCESS',
}
