export default {
  isSomeConnectionDisconnected({ connections }, getters, rootState) {
    if (rootState.auth.isHerokuRequired) {
      return (
        connections.data.some((el) => el.status !== 'connected') ||
        connections.data.length < 2
      )
    } else {
      return (
        connections.data.find((el) => el.systemType === 'sfdc')?.status !==
        'connected'
      )
    }
  },
  disconnectedConnectionsNames({ connections }, getters, rootState) {
    const invalidConnections = connections.data
      .filter((el) => el.status !== 'connected')
      .map((el) => {
        return {
          id: el.id,
          isDisconnected: true,
          label: el.systemTypeLabel,
          authStatus: el.authStatus,
        }
      })

    if (
      !connections.data.find((el) => el.systemType === 'heroku') &&
      rootState.auth.isHerokuRequired
    ) {
      invalidConnections.push({
        isMissing: true,
        label: 'Heroku',
      })
    }

    if (!connections.data.find((el) => el.systemType === 'sfdc')) {
      invalidConnections.push({
        isMissing: true,
        label: 'Salesforce',
      })
    }

    return invalidConnections
  },
}
