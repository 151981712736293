export const isSPA = process.env.VUE_APP_SPA === 'true'
export const isQA = ['local', 'qa', 'pre-release'].includes(
  process.env.VUE_APP_ENV
)
export const isNotProduction = [
  'local',
  'qa',
  'staging',
  'pre-release',
].includes(process.env.VUE_APP_ENV)
