import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import auth from './auth'
import account from './account'
import dashboard from './dashboard'
import businessRules from './business-rules'
import generalSettings from './general-settings'
import onEntry from './on-entry'
import cleanVerify from './clean-verify'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    account,
    dashboard,
    'business-rules': businessRules,
    'general-settings': generalSettings,
    'on-entry': onEntry,
    'clean-verify': cleanVerify,
  },
})

export default store
