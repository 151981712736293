import mutationTypes from './mutation-types'

export default {
  [mutationTypes.CREATING_CONNECTIONS_SUCCESS]: (state, payload) => {
    state.connections.data = [...state.connections.data, payload]
  },

  [mutationTypes.UPDATING_CONNECTIONS_SUCCESS]: (state, payload) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === payload.id) {
        return payload
      }
      return el
    })
  },

  [mutationTypes.GETTING_CONNECTIONS_PENDING]: (state) => {
    state.connections.loading = true
    state.connections.error = false
  },
  [mutationTypes.GETTING_CONNECTIONS_SUCCESS]: (state, payload) => {
    state.connections.data = payload
    state.connections.loading = false
  },
  [mutationTypes.GETTING_CONNECTIONS_ERROR]: (state) => {
    state.connections.loading = false
    state.connections.error = true
  },

  [mutationTypes.DELETING_CONNECTIONS_PENDING]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: true,
        }
      }
      return el
    })
  },
  [mutationTypes.DELETING_CONNECTIONS_SUCCESS]: (state, id) => {
    state.connections.data = state.connections.data.filter((el) => el.id !== id)
  },
  [mutationTypes.DELETING_CONNECTIONS_ERROR]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: false,
        }
      }
      return el
    })
  },

  [mutationTypes.CONNECTING_CONNECTIONS_PENDING]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isConnecting: true,
        }
      }
      return el
    })
  },
  [mutationTypes.CONNECTING_CONNECTIONS_SUCCESS]: (state, connection) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === connection.id) {
        return connection
      }
      return el
    })
  },
  [mutationTypes.CONNECTING_CONNECTIONS_ERROR]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isConnecting: false,
        }
      }
      return el
    })
    state.connections.loading = false
    state.connections.error = true
  },

  [mutationTypes.DISCONNECTING_CONNECTIONS_PENDING]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDisconnecting: true,
        }
      }
      return el
    })
  },
  [mutationTypes.DISCONNECTING_CONNECTIONS_SUCCESS]: (state, connection) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === connection.id) {
        return connection
      }
      return el
    })
  },
  [mutationTypes.DISCONNECTING_CONNECTIONS_ERROR]: (state, id) => {
    state.connections.data = state.connections.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDisconnecting: false,
        }
      }
      return el
    })
    state.connections.loading = false
    state.connections.error = true
  },

  [mutationTypes.GETTING_MULTIMAPS_PENDING]: (state) => {
    state.multimaps.loading = true
    state.multimaps.error = false
  },
  [mutationTypes.GETTING_MULTIMAPS_SUCCESS]: (state, payload) => {
    state.multimaps.data = payload
    state.multimaps.loading = false
  },
  [mutationTypes.GETTING_MULTIMAPS_ERROR]: (state) => {
    state.multimaps.loading = false
    state.multimaps.error = true
  },

  [mutationTypes.DELETING_MULTIMAPS_PENDING]: (state, id) => {
    state.multimaps.data = state.multimaps.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: true,
        }
      }
      return el
    })
  },
  [mutationTypes.DELETING_MULTIMAPS_SUCCESS]: (state, id) => {
    state.multimaps.data = state.multimaps.data.filter((el) => el.id !== id)
  },
  [mutationTypes.DELETING_MULTIMAPS_ERROR]: (state, id) => {
    state.multimaps.data = state.multimaps.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: false,
        }
      }
      return el
    })
  },

  [mutationTypes.CREATING_MULTIMAPS_SUCCESS]: (state, payload) => {
    state.multimaps.data = [...state.multimaps.data, payload]
  },

  [mutationTypes.UPDATING_MULTIMAPS_SUCCESS]: (state, payload) => {
    state.multimaps.data = state.multimaps.data.map((el) => {
      if (el.id === payload.id) {
        return payload
      }
      return el
    })
  },
}
