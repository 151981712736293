import * as connectionsAPI from '@/api/general-settings/connections'
import * as multimapAPI from '@/api/general-settings/multimaps'

import mutationTypes from './mutation-types'

export default {
  async getConnections({ commit }) {
    commit(mutationTypes.GETTING_CONNECTIONS_PENDING)

    try {
      const connections = await connectionsAPI.getConnections()
      commit(mutationTypes.GETTING_CONNECTIONS_SUCCESS, connections)
    } catch (error) {
      commit(mutationTypes.GETTING_CONNECTIONS_ERROR, error)
      throw error
    }
  },

  async deleteConnection({ commit, dispatch }, id) {
    commit(mutationTypes.DELETING_CONNECTIONS_PENDING, id)

    try {
      await connectionsAPI.deleteConnection(id)
      commit(mutationTypes.DELETING_CONNECTIONS_SUCCESS, id)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Connection successfully deleted.' },
        { root: true }
      )
    } catch (error) {
      commit(mutationTypes.DELETING_CONNECTIONS_ERROR, id)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },

  async connect({ commit, dispatch }, id) {
    commit(mutationTypes.CONNECTING_CONNECTIONS_PENDING, id)

    try {
      const connection = await connectionsAPI.connect(id)
      commit(mutationTypes.CONNECTING_CONNECTIONS_SUCCESS, connection)

      if (connection.status === 'connected') {
        dispatch(
          'app/showSuccessNotification',
          { message: 'Connected successfully.' },
          { root: true }
        )
      } else if (connection.status === 'disconnected') {
        commit(mutationTypes.CONNECTING_CONNECTIONS_ERROR, connection.id)

        if (connection.authStatus === 'unknown') {
          dispatch(
            'app/showErrorNotification',
            {
              message: 'Something went wrong. Please try again later!',
            },
            { root: true }
          )
        } else {
          dispatch(
            'app/showErrorNotification',
            {
              message: 'Invalid credentials. Please revise them and try again.',
            },
            { root: true }
          )
        }
      }

      return connection
    } catch (error) {
      commit(mutationTypes.CONNECTING_CONNECTIONS_ERROR, id)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )

      throw error
    }
  },

  async updateConnection({ commit, dispatch }, payload) {
    try {
      const connection = await connectionsAPI.updateConnection(payload)
      commit(mutationTypes.UPDATING_CONNECTIONS_SUCCESS, connection)

      dispatch(
        'app/showSuccessNotification',
        { message: 'Connection successfully created.' },
        { root: true }
      )

      return connection
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          'app/showErrorNotification',
          {
            message: 'Invalid credentials. Please revise them and try again.',
          },
          { root: true }
        )
      } else {
        dispatch(
          'app/showErrorNotification',
          { message: 'Something went wrong. Please try again later!' },
          { root: true }
        )
      }

      throw error
    }
  },

  async createConnection({ commit, dispatch }, payload) {
    try {
      const connection = await connectionsAPI.createConnection(payload)
      commit(mutationTypes.CREATING_CONNECTIONS_SUCCESS, connection)

      dispatch(
        'app/showSuccessNotification',
        { message: 'Connection successfully created.' },
        { root: true }
      )

      return connection
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          'app/showErrorNotification',
          {
            message: 'Invalid credentials. Please revise them and try again.',
          },
          { root: true }
        )
      } else {
        dispatch(
          'app/showErrorNotification',
          { message: 'Something went wrong. Please try again later!' },
          { root: true }
        )
      }
      throw error
    }
  },

  async disconnect({ commit, dispatch }, id) {
    commit(mutationTypes.DISCONNECTING_CONNECTIONS_PENDING, id)

    try {
      const connection = await connectionsAPI.disconnect(id)
      commit(mutationTypes.DISCONNECTING_CONNECTIONS_SUCCESS, connection)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Successfully disconnected.' },
        { root: true }
      )
    } catch (error) {
      commit(mutationTypes.DISCONNECTING_CONNECTIONS_ERROR, id)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },

  async getMultimaps({ commit }) {
    commit(mutationTypes.GETTING_MULTIMAPS_PENDING)

    try {
      const multimaps = await multimapAPI.getMultimaps()
      commit(mutationTypes.GETTING_MULTIMAPS_SUCCESS, multimaps)
    } catch (error) {
      commit(mutationTypes.GETTING_MULTIMAPS_ERROR, error)
      throw error
    }
  },

  async deleteMultimap({ commit, dispatch }, id) {
    commit(mutationTypes.DELETING_MULTIMAPS_PENDING, id)

    try {
      await multimapAPI.deleteMultimap(id)
      commit(mutationTypes.DELETING_MULTIMAPS_SUCCESS, id)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Multimap successfully deleted.' },
        { root: true }
      )
    } catch (error) {
      commit(mutationTypes.DELETING_MULTIMAPS_ERROR, id)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },

  async updateMultimap({ commit, dispatch }, payload) {
    try {
      const multimap = await multimapAPI.updateMultimap(payload)
      commit(mutationTypes.UPDATING_MULTIMAPS_SUCCESS, multimap)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Multimap successfully updated.' },
        { root: true }
      )
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },

  async createMultimap({ commit, dispatch }, payload) {
    try {
      const multimap = await multimapAPI.createMultimap(payload)
      commit(mutationTypes.CREATING_MULTIMAPS_SUCCESS, multimap)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Multimap successfully created.' },
        { root: true }
      )
      return multimap
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
}
